import axios from 'axios';

const GalleryApi = {
    getFolderNames : () => {
        return axios.get('/api/gallery/foldername', {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    },
    getFolderData : (id) => {
        return axios.get('/api/gallery/data/' + id, {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    }
};

export default GalleryApi;