import React, { Fragment, useEffect, useState } from 'react'
//import { Carousel } from 'react-responsive-carousel'
import GalleryApi from '../../apis/GalleryApi'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

export default function GalleryComponent ({ folderId }) {
    const [folderInfo, setFolderInfo] = useState({})
    const [folderImages, setFolderImages] = useState([])
    //const [showLightbox, setShowLightbox] = useState({});

    const loadData = async id => {
        console.log('[GalleryComponent] loading folder ', id)
        var result = await GalleryApi.getFolderData(id)
        //setFolderInfo(result.data)
        var images = result.data.map(x => {
            return {
                id : x.id,
                uri: x.uri,
                width: x.width,
                height: x.height,
                showLightbox: false
            }
        })
        setFolderImages(images)

    }

    useEffect(() => {
        console.log('[GalleryComponent] folderImages set : ', folderImages)
        
    }, [folderImages])

    useEffect(() => {
        loadData(folderId)
    }, [])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    const hideLightboxFor = id => {
        let x = [...folderImages];
        var el = x.find(q => q.id == id)
        el.showLightbox = false;
        
        setFolderImages(x)
    }
    const showLightboxFor = id => {
        console.log('[showlightboxfor] : ', `${id}`)
        let x = [...folderImages];
        var el = x.find(q => q.id == id)
        if(el == null || typeof(el) == "undefined") return;
        el.showLightbox = true;
        setFolderImages(x)
    }
    return (
        <Fragment>
            <Carousel
                responsive={responsive}
                showDots={false}
                itemClass='test'
                key={`carousel_${folderId}`}
            >
                {folderImages && folderImages.length > 0 &&
                    folderImages.map((x, index) => {
                        return (
                            <Fragment key={`frag_img_${x.id}`}>
                                <a
                                                                key={`a_${x.id}`}
                                                                onClick={() =>
                                                                    showLightboxFor(
                                                                        x.id
                                                                    )
                                                                }
                                                            >
                                <img
                                    key={`img_${x.id}`}
                                    src={`/storage/${x.uri}`}
                                />
                                </a>
                                {x.showLightbox  && (
                                        <Lightbox
                                            key={`lb_${x.id}`}
                                            mainSrc={`/storage/${x.uri}`}
                                            onCloseRequest={() =>
                                                hideLightboxFor(x.id)
                                            }
                                        ></Lightbox>
                                    )}
                            </Fragment>
                        )
                    })}
            </Carousel>
        </Fragment>
    )
}
