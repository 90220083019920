import './advancedgallery.scss'

import React, { Fragment } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import PhotosApi from '../../../admin/apis/photosApi'
import Gallery from 'react-grid-gallery'

export default function AdvancedGallery ({
    folderId = null,
    disableGoUpButton = false
}) {
    const [folderData, setFolderData] = useState([])
    const [showFolderContent, setShowFolderContent] = useState(false)
    const [folderContentImages, setFolderContentImages] = useState([])

    const loadData = async () => {
        const data = (await PhotosApi.getFolderInfoForPage()).data
        console.log(
            '[AdvancedGallery::loadData] Received Data from backend : ',
            data
        )
        let folderInformation = Object.keys(data).map((key, index) => {
            let v = data[key]
            console.log('mapping : ', v)
            if (v == null) return null
            return {
                id: v.folder_id,
                title: key,
                uri: '/storage/' + v.uri
            }
        })
        folderInformation = folderInformation.filter(v => v != null)
        console.log(
            '[AdvancedGallery::loadData] mapped value : ',
            folderInformation
        )
        setFolderData(folderInformation)
    }

    const loadDataForFolder = async folderId => {
        const folderContentData = (
            await PhotosApi.getFolderContentData(folderId)
        ).data
        console.log('data : ', folderContentData)
        var fci = folderContentData.map((f, i) => {
            let h = f.height
            let w = f.width
            let tw = 320
            let th = 212
            if (h > w) {
                tw = 212
                th = 320
            }
            return {
                src: '/storage/' + f.uri,
                thumbnail: '/storage/' + f.uri,
                thumbnailWidth: tw,
                thumbnailHeight: th
            }
        })
        setFolderContentImages(fci)
        setShowFolderContent(true)
    }

    useEffect(() => {
        if (folderId == null) loadData()
        else {
            loadDataForFolder(folderId)
        }
    }, [])

    const onFolderClick = async (e, id) => {
        console.log('[AdvancedGallery::onFolderClick] clicking folder : ', id)

        let folderContentData = (await PhotosApi.getFolderContentData(id)).data
        console.log(
            '[AdvancedGallery::onfolderClick] loaded Folder Data : ',
            folderContentData
        )
        var fci = folderContentData.map((f, i) => {
            let h = f.height
            let w = f.width
            let tw = 320
            let th = 212
            if (h > w) {
                tw = 212
                th = 320
            }
            return {
                src: '/storage/' + f.uri,
                thumbnail: '/storage/' + f.uri,
                thumbnailWidth: tw,
                thumbnailHeight: th
            }
        })
        setFolderContentImages(fci)
        setShowFolderContent(true)
    }

    useEffect(() => {
        console.log('Folder content images : ', folderContentImages)
    }, [folderContentImages])

    const levelUpFolder = () => {
        setFolderContentImages([])
        setShowFolderContent(false)
    }

    return (
        <Fragment>
            <Row>
                {!showFolderContent && (
                    <Fragment>
                        {folderData &&
                            folderData.length > 0 &&
                            folderData.map((f, index) => (
                                <Col sm={3} key={index}>
                                    <div className='imagebox'>
                                        <a
                                            onClick={e =>
                                                onFolderClick(e, f.id)
                                            }
                                        >
                                            <img
                                                src={f.uri}
                                                className='category-banner img-responsive'
                                            ></img>
                                            <span className='imagebox-desc'>
                                                {f.title}
                                            </span>
                                        </a>
                                    </div>
                                </Col>
                            ))}
                    </Fragment>
                )}
                {showFolderContent && (
                    <Fragment>
                        <Col>
                            {!disableGoUpButton && (
                                <button
                                    className='btn btn-sm'
                                    style={{ color: '#c10202' }}
                                    onClick={() => levelUpFolder()}
                                >
                                    <i className='fas fa-level-up-alt'></i>
                                    &nbsp;Ga Terug{' '}
                                </button>
                            )}

                            <Gallery
                                images={folderContentImages}
                                enableLightbox={true}
                                backdropClosesModal
                            />
                        </Col>
                    </Fragment>
                )}
            </Row>
        </Fragment>
    )
}
