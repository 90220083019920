import React, { Fragment } from 'react'
import { Col, Container, Row } from 'reactstrap'

import Header from '../components/header/header'
import Banner from '../components/banner/banner'
import Footer2 from '../components/footer/footer'
import { Link } from 'react-router-dom'

export default function MainPage () {
    return (
        <Fragment>
            <Header />
            <Banner />

            <section className='page content-home'>
                <div className='container'>
                    <header className='page-title'>
                        <h1>
                            <span className='subtitle'>Bouwwerken Thys:</span>{' '}
                            uw bouwbedrijf uit Oudsbergen
                        </h1>
                    </header>
                    <article className='content'>
                        <p>
                            Bouwwerken Thys is een{' '}
                            <strong>bouwbedrijf in Oudsbergen</strong> dat
                            inmiddels meer dan 40 jaar bestaat. Wij realiseren
                            tal van{' '}
                            <Link to='/nieuwbouw'>nieuwbouwwoningen</Link> en&nbsp;
                            <Link to='/renovaties'>renovatieprojecten</Link> in
                            de ruime regio. Wanneer u op zoek bent naar een
                            bekwame partner inzake bouwen en verbouwen, dan
                            weten wij u perfect van dienst te zijn.&nbsp;
                        </p>
                        <h2>Toonaangevend bouwbedrijf</h2>
                        <p>
                            Bouwwerken Thys is een echt familiebedrijf dat werd
                            opgericht in 1976 als een bouwfirma voor
                            ruwbouwwerken. Al snel wijzigde de dienstverlening,
                            want naast het winddicht afleveren van woningen,
                            kwamen er allerhande afwerkingswerken aan te pas.
                            Het ‘sleutel op de deur’ -concept was geboren en
                            sindsdien zijn wij met Bouwwerken Thys een
                            toonaangevend bouwbedrijf in de sector. Onze drang
                            naar innovatie en optimalisatie is voor veel
                            collega’s een inspirerend facet van ons bedrijf.
                        </p>
                        <h2>Traditionele woningbouw</h2>
                        <p>
                            Wij zijn dag in dag uit in de weer om bouwprojecten
                            te realiseren. Zowel voor nieuwbouwwoningen als
                            appartementen, villa’s en renovatieprojecten kunt u
                            een beroep doen op onze complete service. Wij houden
                            daarbij rekening met uw wensen, verwachtingen en
                            favoriete bouwstijl en werken nauw samen met
                            architecten om al uw bouwplannen in goede banen te
                            leiden.
                        </p>
                        <p>
                            Wij voeren alle projecten uit in een straal van 60km
                            rondom Oudsbergen in de provincies Limburg,
                            Antwerpen en Vlaams-Brabant. De werken worden
                            uitgevoerd met de beste materialen en volgens de
                            regels van de kunst. Wij beschikken over alle
                            expertise voor het excellent uitvoeren van{' '}
                            <Link to='/nieuwbouw'>bouwwerken</Link> en&nbsp;
                            <Link to='/renovaties'>verbouwingen</Link>. Na
                            afloop wordt de werf opgeruimd en proper
                            achtergelaten.
                        </p>
                        <h2>Alle hedendaagse specificaties</h2>
                        <p>
                            Als <strong>gedreven bouwspecialist</strong>,
                            besteden wij veel aandacht aan de huidige trends,
                            normen en bouwvoorschriften. Ondanks een steeds
                            strenger beleid voor onder meer het voorzien van
                            verluchting en isolatie, weten wij altijd uitstekend
                            de combinactie te maken tussen noodzakelijke
                            voorzieningen enerzijds en uw comfort, luxe en
                            persoonlijke verwachtingen anderzijds.
                        </p>
                        <p>
                            Wij hebben alle nodige certificaten, attesten en
                            opleidingen om professionele bouwwerken te mogen
                            uitvoeren. <br />
                            Aarzel niet om{' '}
                            <Link to='/contact'>contact</Link> op te nemen
                            indien u vragen heeft of meer info wenst over onze
                            diensten.
                        </p>
                    </article>
                </div>
            </section>

            <section className='boxes'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-4 box box01'>
                            <Link to={'nieuwbouw'}>
                                <p>
                                    <span className='photo'>&nbsp;</span>
                                </p>
                                <div className='box-txt'>
                                    <h3>Nieuwbouw</h3>
                                    <p>
                                        Wij voeren alle nieuwbouwwerken uit,
                                        waarbij u de keuze heeft tussen
                                        verschillende bouwstijlen en
                                        bouwformules. Wij plaatsen ook
                                        muurisolatie.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-sm-4 box box02'>
                            <Link to={'renovaties'}>
                                <p>
                                    <span className='photo'>&nbsp;</span>
                                </p>
                                <div className='box-txt'>
                                    <h3>Renovaties</h3>
                                    <p>
                                        Alle renovatiewerken behoren tot ons
                                        vakgebied: afbraakwerken, metselwerken,
                                        isolatiewerken en nog veel meer. Zowel
                                        kleine als grote renovatieprojecten.
                                    </p>
                                </div>
                            </Link>
                        </div>
                        <div className='col-sm-4 box box03'>
                            <Link to={'referenties'}>
                                <p>
                                    <span className='photo'>&nbsp;</span>
                                </p>
                                <div className='box-txt'>
                                    <h3>Referenties</h3>
                                    <p>...</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />
        </Fragment>
    )
}
