import React, { Fragment } from "react";
import Header from "../components/header/header";
import Banner from "../components/banner/banner";
import Footer from "../components/footer/footer";
import AdvancedGallery from "../components/Gallery/advancedgallery";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";


export default function Renovaties() {
    return (
        <Fragment>
            <Header />
            <Banner />

            <section className="page">
                <div className="container">
                    <header className="page-title">

                        <h1>Van de kleinste tot de grootste renovatiewerken</h1>

                    </header>
                    <article className="content">

                        <p>U kunt Bouwwerken Thys inschakelen voor het uitvoeren van <strong>renovatiewerken in Oudsbergen</strong> en omstreken.
                            Wij beschikken over meer dan 40 jaar ervaring in de bouwsector en hebben al tal van referenties op onze
                            naam staan. In een straal van 60km rondom Oudsbergen behartigen wij in de provincies Limburg, Antwerpen en
                            Vlaams-Brabant dus alle renovatiewerken, zowel voor kleine als voor grote woningen.</p>
                        <h2>Plaatsen van gevelbekleding</h2>
                        <p>Wij plaatsen duurzame gevelbekleding voor verschillende doeleinden. Daarbij maken wij gebruik van cederhout,
                            dat bekend staat om zijn duurzame, stabiele, lichte en makkelijk bewerkbare eigenschappen. Zowel om uw gevel
                            te beschermen, storende schade te camoufleren als een louter esthetische toepassing leent cederhout zich perfect.</p>
                        <h2>Verdiepingen en bijgebouwen</h2>
                        <p>Als ervaren <Link to="/nieuwbouw" title="Bouwwerken Thys BVBA - Opglabbeek - Nieuwbouw">aannemer</Link> beschikken
                            wij over alle expertise en materialen om op een vlotte en veilige manier een extra bijgebouw te plaatsen of een
                            extra verdieping aan uw woning toe te voegen. Voor de <strong>spouwisolatie</strong> maken wij gebruik van
                            Eurowall (firma Recticel).</p>
                        <h2>Uitbreken van muren</h2>
                        <p>Wij breken op een veilige manier muren uit: om kamers groter te maken, of om poutrellen te steken. Wij
                            hebben daarvoor het nodige materiaal om dat te doen, net zoals het vakmanschap om goed, veilig en proper werk te leveren.</p>
                        <h2>Volledige renovaties</h2>
                        <p>Het uitvoeren van renovatiewerken is voor ons een fluitje van een cent. Wij voeren gedeeltelijke en
                            volledige (badkamer)renovaties uit, of strippen een volledige woning van kelder tot dak om ze nadien
                            terug af te werken en in te richten.</p>
                        <h2>Diverse grondwerken</h2>
                        <p>Wij kennen de techniek van ondermetselen uitstekend. Zo zijn wij in staat om voor een bestaande woning
                            een kelder bij te maken, of een bestaande kelder dieper te maken. Zowel kleine als grote kelders behoren
                            tot de mogelijkheden.</p>
                        <h2>Informatie en contact</h2>
                        <p>Als gedreven bouwspecialist, besteden wij veel aandacht aan de huidige trends, normen en bouwvoorschriften.
                            Wij weten altijd uitstekend de combinactie te maken tussen noodzakelijke voorzieningen enerzijds en uw comfort,
                            luxe en persoonlijke verwachtingen anderzijds.</p>
                        <p>Wij hebben alle nodige <strong>certificaten, attesten</strong> en opleidingen om professionele bouwwerken te
                            mogen uitvoeren. Bekijk onze realisaties&nbsp;en aarzel niet om <Link to="/contact">contact</Link> op te nemen indien u
                            vragen heeft of meer info wenst over onze diensten.</p>
                        <div>
                        </div>
                        <Container>
                        <AdvancedGallery />
                        </Container>
                    </article>
                </div>

            </section>
            <section className="spacer"></section>

            <Footer />
        </Fragment >
    );

}