import React, { Fragment } from 'react'
import { Row, Col, Container, Footer } from 'reactstrap'

export default function Footer2 () {
    return (
        <Fragment>
            <footer className='bottom'>
                <Container>
                    <Row>
                        <Col xs={8}>
                            <h2>Contacteer ons</h2>
                            <Row>
                                <Col className='bloc01' xs={6}>
                                    <Row>
                                        <Col xs={1}>
                                            <a
                                                href='https://www.google.com/maps/place/Geensstraat+57,+3660+Oudsbergen/@51.0558437,5.6105417,17'
                                                target={'_blank'}
                                            >
                                                <i className='bi bi-map'></i>
                                            </a>
                                        </Col>
                                        <Col>
                                            Bouwwerken Thys BVBA
                                            <br /> Geensstraat 57
                                            <br /> 3660 Oudsbergen (Opglabbeek)
                                            - België
                                            <br />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='bloc02'>
                                    <Row>
                                        <Col xs={1}>
                                            <i className='bi bi-telephone'></i>
                                        </Col>
                                        <Col>
                                            Gsm:
                                            <a href='tel:+32476582806'>
                                                +32 (0)476/58 28 06
                                            </a>
                                            <br />
                                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            <a href='te::+32496702170'>
                                                +32 (0)496/70 21 70
                                            </a>
                                            <br />
                                            <a href='mailto:yves@bouwwerkenthys.be'>
                                                yves @bouwwerkenthys.be
                                            </a>
                                            <br /> BTW BE0454 977 510
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <h2>Volg ons</h2>
                        </Col>
                    </Row>
                </Container>
            </footer>

        </Fragment>
    )
}
