import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container , Row} from 'reactstrap'

export default function Header () {
    return (
        <header className='top'>
            <Container>
                <Row>
                    <div className='logo'>
                        <a href='/' title='Bouwwerken Thys BV'>
                            <img src='/assets/images/logo.png' />
                        </a>
                    </div>
                </Row>
            </Container>
        </header>
    )
}
