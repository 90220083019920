import './contact.scss';

import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';

import { useForm } from 'react-hook-form';

import Header from '../components/header/header';
import Banner from '../components/banner/banner';
import Footer from '../components/footer/footer';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ContactApi from '../../admin/apis/ContactApi';

export default function ContactPage() {
    const defaultFormValues = {
        adres: '',
        naam: '',
        algemenevoorwaarden: false,
        email: '',
        opmerking: '',
        telefoon: ''
    };

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [mailSent, setMailSent] = useState(false);

    const onSubmitForm = async (data) => {
        console.log('Submitting form : ', data);
        await ContactApi.postContactForm(data);
        console.log('Finished submitting form');
        clearForm();
    }

    const clearForm = () => {
        setSubmitButtonDisabled(true);
        setRecaptchaValue('');
        reset(defaultFormValues);
        setMailSent(true);
    }

    const isValidEmail = email =>
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        );

    const handleEmailValidation = email => {
        console.log("ValidateEmail was called with", email);

        const isValid = isValidEmail(email);

        const validityChanged = (errors.email && isValid) || (!errors.email && !isValid);
        if (validityChanged) {
            console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
        }

        return isValid;
    };

    const onChangeRecaptcha = (value) => {
        console.log('value : ', value);
        if (value != null) {
            setRecaptchaValue(value);
            setSubmitButtonDisabled(false);
        } else {
            setRecaptchaValue('');
            setSubmitButtonDisabled(true);
        }
    }


    return (
        <Fragment>
            <Header />
            <Banner />

            <section className="page">
                <div className="container">
                    <header className="page-title">

                        <h1>Contacteer Bouwwerken Thys</h1>

                    </header>
                    <article className="content">

                        <p><strong>Bouwwerken Thys in Oudsbergen</strong> realiseert voor u de allermooiste bouwwerken in de regio.
                            In Limburg, maar ook in de provincies Antwerpen en Vlaams-Brabant (tot een straal van 60km rondom Oudsbergen)
                            kunt u een beroep doen op onze <Link to="/"> complete dienstverlening</Link>. Wij <Link to="/nieuwbouw">bouwen</Link>
                            en <Link to="/renovaties">verbouwen</Link>, tot wij uw droomproject gerealiseerd hebben.</p>
                        <h2>40 jaar ervaring in de sector</h2>
                        <p>Onze <strong>bouwfirma</strong> kan terugvallen op een ruime ervaring, een uitstekende kennis
                            en waardevolle inzichten. Neem daarom contact op met ons wanneer u op zoek bent naar een nieuwe woning, of naar een
                            partner om een mooi project mee te realiseren.</p>
                        <h2>Meer informatie aanvragen</h2>
                        <p>Laat hier uw gegevens na en wij contacteren u spoedig. Formuleer gerust uw vraag, zodat wij er
                            duidelijk en gericht op kunnen antwoorden. Voor alle bouwwerken staan wij tot uw dienst.</p>

                    </article>
                    <Row>
                        <Col md={8}>
                            <form onSubmit={handleSubmit(onSubmitForm)}>

                                <Row className="form-group">
                                    <label htmlFor="naam" className="col-sm-2 col-form-label">Naam</label>
                                    <Col sm={8}>
                                        <input {...register('naam', { required: true })} className="form-control " />
                                        {errors && errors.naam && <div className="alert2"> <p>Naam is verplicht! </p></div>}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label htmlFor="adres" className="col-sm-2 col-form-label">Adres</label>
                                    <Col sm={8}>
                                        <input {...register('adres', { required: false })} name='adres' className="form-control " />

                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label htmlFor="telefoon" className="col-sm-2 col-form-label">Telefoon</label>
                                    <Col sm={8}>
                                        <input {...register('telefoon', { required: false })} name='telefoon' className="form-control " />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                                    <Col sm={8}>
                                        <input {...register('email', { required: true, validate: handleEmailValidation })} name='email' className="form-control " />
                                        {errors && errors.email && <div className="alert2"> <p>Email is verplicht!</p></div>}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <label htmlFor="opmerking" className="col-sm-2 col-form-label">Opmerking</label>
                                    <Col md={8}>
                                        <textarea {...register('opmerking', { required: true })} name="opmerking" className="form-control" />
                                        {errors && errors.opmerking && <div className="alert2"><p>Opmerking is veprlicht!</p></div>}
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <FormGroup check>
                                        <Label check>
                                            <input {...register('algemenevoorwaarden', { required: true })} type="checkbox" />{' '}
                                            Ik accepteer de algemene voorwaarden
                                        </Label>
                                        {errors && errors.algemenevoorwaarden && <div className="alert2"><p>Algemene voorwaarden zijn verplicht!</p></div>}
                                    </FormGroup>
                                </Row>


                                <Row className="form-group">
                                    {
                                        // key : 6LfunPocAAAAAJf_6jvPYgpOtwgyYsAsRfezLbix
                                        // secret : 6LfunPocAAAAAI89w5Jl8q6H3-bDZ61ARiXTo5-H
                                        //key : 6LfeR30fAAAAADAjGp6ZNnA5QrSzgb2KNLq9rmHl
                                        //secret : 6LfeR30fAAAAAGnUDduyTJ61WBTDXHcGegetYlRP

                                    }
                                    <ReCAPTCHA sitekey="6LfeR30fAAAAADAjGp6ZNnA5QrSzgb2KNLq9rmHl" onChange={onChangeRecaptcha} />
                                    <input type="submit" disabled={submitButtonDisabled} />

                                </Row>
                                {
                                    mailSent &&
                                    <Row className="form-group">
                                        <span className="text-success">
                                            <strong>Bedankt voor uw reactie. We zullen zo snel mogelijk contact opnemen.</strong>
                                        </span>
                                    </Row>
                                }

                            </form>
                        </Col>
                        <Col md={3}>
                            <aside className="sideb3ar">
                                <h2>Contact informatie</h2>
                                <h5>Bouwwerken Thys BVBA</h5>
                                <p>Geensstraat 57<br /> 3660 Opglabbeek - België</p>
                                <p>Gsm: +32 (0)476/58 28 06<br />&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; +32 (0)496/70 21 70<br />
                                    <a href="mailto:yves@bouwwerkenthys.be">yves@bouwwerkenthys.be</a></p>
                                <p>BTW BE0454 977 510</p>
                            </aside>
                        </Col>
                    </Row>
                </div>
            </section>

            <Footer />
        </Fragment >
    );
}