import axios from 'axios';


const ForSaleApi = {
    getAll : () => {
        return axios.get('/api/forsale', { headers: { Authorization: 'Bearer ' + localStorage.getItem("user.api_token") } });
    },
    create: (o) => {
        return axios.post('/api/forsale', { o }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user.api_token'),
                'Content-Type': 'application/json'
            }
        });
    },
    attachImage : (formData) => {
        console.log('[ForSaleApi::attachImage] calling attach with formData : ', formData);
        return axios.post('/api/forsalephoto', {formData}, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user.api_token'),
                'Content-Type': 'application/json'
            }
        });
    },
    delete : (id) => {
        return axios.delete('/api/forsale', { headers: { Authorization: 'Bearer ' + localStorage.getItem("user.api_token") }, data : {id: id} });
    },
    getForWebsite: () => {
        return axios.get('/api/forsale/website');
    }
};
export default ForSaleApi;