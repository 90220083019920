
import React, { Fragment, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import ForSaleApi from "../../admin/apis/ForSaleApi";
import Banner from "../components/banner/banner";
import Footer2 from "../components/footer/footer";
import Header from "../components/header/header";

import './tekoop.scss';

export default function TeKoopPage() {
    const [data, setData] = useState([]);
    let folderData = null;

    const loadData = async () => {
        const apiData = (await ForSaleApi.getForWebsite()).data;
        console.log('apiData : ', apiData);

        const mappedData = apiData.map((x, index) => {
            const newPhotos = x.photos.map((image, i) => {
                image.showLightbox = false;
                return image;
            });
            x.photos = newPhotos;
            return x;
        })
        console.log('Data : ', mappedData);
        setData(mappedData);
    }

    useEffect(() => {
        loadData();
    }, []);

    const hideLightboxFor = (imageId, dataId) => {
        console.log('[showlightboxfor] : ', `${imageId} - ${dataId}`)
        let xx = [...data];
        let x = xx.find(q => q.id == dataId);
        let el = x.photos.find(q => q.id == imageId);

        if (el == null || typeof (el) == "undefined") return;
        el.showLightbox = false;
        setData(xx);
    }
    const showLightboxFor = (imageId, dataId) => {
        console.log('[showlightboxfor] : ', `${imageId} - ${dataId}`)
        let xx = [...data];
        let x = xx.find(q => q.id == dataId);
        let el = x.photos.find(q => q.id == imageId);

        if (el == null || typeof (el) == "undefined") return;
        el.showLightbox = true;
        setData(xx);
    }

    return (
        <Fragment>
            <Header />
            <Banner />
            <section className='page'>
                <div className='container'>
                    <header className='page-title'>
                        <h1>Te Koop</h1>
                    </header>
                    <article className='content'>
                        <Container>
                            <Row>
                                {data &&
                                    data.map((x, index) => {
                                        return (
                                            <Fragment key={`frag_${x.id}`}>
                                                <Col xs={3} sm={3} >
                                                    <div className="card eventDisplay">
                                                        <CardHeader>
                                                            <h3 key={`h2_${x.id}`}>{x.title}</h3>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                {
                                                                    x && x.photos && x.photos.map((y, i) => {

                                                                        return (
                                                                            <Fragment>

                                                                                <Col xs={6} className="mb-1">
                                                                                    <a key={`a_${y.id}`}
                                                                                        onClick={() =>
                                                                                            showLightboxFor(
                                                                                                y.id, x.id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <img src={'/storage/' + y.uri} alt="" key={'img_' + y.uri} className={"w-100"} data-showLightbox={y.showLightbox} />
                                                                                    </a>
                                                                                    {
                                                                                        y.showLightbox &&
                                                                                        <Lightbox
                                                                                            key={`lb_${y.id}`}
                                                                                            mainSrc={`/storage/${y.uri}`}
                                                                                            onCloseRequest={() =>
                                                                                                hideLightboxFor(y.id, x.id)
                                                                                            }
                                                                                        ></Lightbox>
                                                                                    }
                                                                                </Col>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col xs={11}>
                                                                    <div className="default-text pt-2">{x.description}</div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </div>
                                                </Col>
                                            </Fragment>
                                        )
                                    })}
                            </Row>
                        </Container>
                    </article>
                </div>
            </section>
            <Footer2 />
        </Fragment>
    )
}