import React, { Fragment, useEffect, useState } from 'react'
import GalleryApi from '../apis/GalleryApi'
import Banner from '../components/banner/banner'
import Footer2 from '../components/footer/footer'
import AdvancedGallery from '../components/Gallery/advancedgallery'
import Header from '../components/header/header'
import './referenties.scss'
import GalleryComponent from '../components/Gallery/GalleryComponent'
import { sortBy } from 'lodash'

export default function Referenties () {
    const [folderData, setFolderData] = useState([])
    const [allImagesInfo, setAllImagesInfo] = useState([])
    const [showLightbox, setShowLightbox] = useState([])

    const loadData = async () => {
        const data = (await GalleryApi.getFolderNames()).data.sort((a, b) => a.sortid > b.sortid ? 1 : -1);
        console.log('[referenties:loadData] data : ', data)
        setFolderData(data)
    }
/*
    useEffect(() => {
        //console.log('[useEffect:allImagesInfo] setting showLightbox stuff on allImagesInfo', allImagesInfo.length);
        let x = []
        for (let a = 0; a < allImagesInfo.length; a++) {
            //console.log('[useEffect:...] images length : ', allImagesInfo[a].images.length);
            for (let b = 0; b < allImagesInfo[a].images.length; b++) {
                x[
                    `${allImagesInfo[a].id}_${allImagesInfo[a].images[b].id}`
                ] = false
                //  console.log('.... ', `${allImagesInfo[a].id}_${allImagesInfo[a].images[b].id}`, x);
            }
        }
        console.log('[useEffect:allImagesInfo] x : ', x)
        setShowLightbox(x)
    }, [allImagesInfo])
    */
/*
    useEffect(async () => {
        console.log('[referenties:folderdata] start loading folderdata')
        let allImageInfo = []
        folderData.map(async (q, index) => {
            let data = (await GalleryApi.getFolderData(q.id)).data
            console.log(' data : ', data)
            allImageInfo.push({
                id: q.id,
                name: q.foldername,
                images: data
            })
        })

        setAllImagesInfo(allImageInfo)
        console.log(
            '[referenties:folderdata] finished loading folderdata : ',
            allImageInfo
        )
    }, [folderData])
*/
    useEffect(() => {
        loadData()
    }, [])
   

   
    
    return (
        <Fragment>
            <Header />
            <Banner />
            <section className='page'>
                <div className='container'>
                    <header className='page-title'>
                        <h1>Referenties</h1>
                    </header>
                    <article className='content'>
                        {folderData &&
                            folderData.map((x, index) => {
                                return (
                                    <Fragment key={`frag_${x.id}`}>
                                        <h2 key={`h2_${x.id}`}>{x.foldername}</h2>
                                        <GalleryComponent folderId={x.id} />
                                    </Fragment>
                                )
                            })}
                    </article>
                </div>
            </section>
            <Footer2 />
        </Fragment>
    )
}
