import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { HashRouter as Router } from 'react-router-dom';

export default class App extends Component {
    render() {
        return (
            <Router>
                <div className="fullsite">
                    <Routes />

                </div>
            </Router>
        )
    }
}

if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}