import axios from 'axios';

const PhotosApi = {
    list: (page = 1) => {
        return axios.get('/api/photos?page=' + page, {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    } ,
    delete: (id) => {
        //return axios.delete('/api/photos/' + id, {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
        return axios.delete('/api/photos', { headers: { Authorization: 'Bearer ' + localStorage.getItem("user.api_token") }, data: { id: id } });
    },

    saveFolderName: (folderName) => {
        return axios.post('/api/gallery/foldername', {folderName : folderName}, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("user.api_token"),
                'Content-Type': 'application/json'
            }
        });
    },
    getFolderNames : () => {
        return axios.get('/api/gallery/foldername', {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    },
    deleteFolderName : (id) => {
        return axios.delete('/api/gallery/foldername',{ headers: { Authorization: 'Bearer ' + localStorage.getItem("user.api_token") }, data: { id: id} });
    },
    folderUp : (id) => {
        return axios.post('/api/gallery/foldernameup', { id: id},
        {
            headers: { 
                Authorization: 'Bearer ' + localStorage.getItem("user.api_token"),
                "Content-Type": 'application/json' 
            }
         } );
    },
    folderImageUp : (id) =>{
        return axios.post('/api/gallery/folderimageup', { id: id},
        {
            headers: { 
                Authorization: 'Bearer ' + localStorage.getItem("user.api_token"),
                "Content-Type": 'application/json' 
            }
         } );
    },
    folderImageDown : (id) =>{
        return axios.post('/api/gallery/folderimagedown', { id: id},
        {
            headers: { 
                Authorization: 'Bearer ' + localStorage.getItem("user.api_token"),
                "Content-Type": 'application/json' 
            }
         } );
    },
    folderDown : (id) => {
        return axios.post('/api/gallery/foldernamedown', { id: id},
        {
            headers: { 
                Authorization: 'Bearer ' + localStorage.getItem("user.api_token"),
                "Content-Type": 'application/json' 
            }
         } );
    },
    getFolderData : (id) => {
        return axios.get('/api/gallery/data/' + id, {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    },
    deleteFolderData : (id) => {
        return axios.delete('/api/gallery/data', {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}, data: {id: id}});
    },
    getFolderInfoForPage : () => {
        return axios.get('/api/gallery/getInfoForPage',  {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    },
    getFolderContentData : (id) => {
        return axios.get('/api/gallery/getFolderContentData/' + id,  {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.api_token")}});
    }
}

export default PhotosApi;