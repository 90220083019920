import React, { useEffect, useState } from "react";
import Gallery from 'react-grid-gallery';
import PhotosApi from "../../../admin/apis/photosApi";

export default function GalleryComponent() {

    const [images, setImages] = useState([]);

    const loadData = async () => {
        const allImages = (await PhotosApi.list()).data.photos;
        console.log('All Images : ', allImages);
        const data = allImages.map((im) => {
            return {
                src: "/storage/" + im.uri,
                thumbnail: "/storage/" + im.uri,
                thumbnailWidth: 320,
                thumbnailHeight: 212
            }
        });
        setImages(data);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Gallery
            images={images}
            enableLightbox={true}
            backdropClosesModal
        />
    )
}