import React from 'react';

import './underconstruction.scss'

export default function () {
    return (
        <div
            className='main-area center-text'
            style={{backgroundImage:'url(assets/images/countdown-3-1600x900.jpg)'}}
            
        >
            <div className='display-table'>
                <div className='display-table-cell'>
                    <h1 className='title font-white'>
                        <b>Comming Soon</b>
                    </h1>
                    <p className='desc font-white'>
                        Our website is currently undergoing scheduled
                        maintenance. We Should be back shortly. Thank you for
                        your patience.
                    </p>
                    {/*
                    <ul className='social-btn font-white'>
                        <li>
                            <a href='#'>facebook</a>
                        </li>
                        <li>
                            <a href='#'>twitter</a>
                        </li>
                        <li>
                            <a href='#'>google</a>
                        </li>
                        <li>
                            <a href='#'>instagram</a>
                        </li>
                    </ul>
                    */}
                </div>
            </div>
        </div>
    )
}
