import React, { Component } from 'react'

import { Route, Switch } from 'react-router-dom'
import ContactPage from './pages/contact'

import MainPage from './pages/mainpage'
import Nieuwbouw from './pages/nieuwbouw'
import Referenties from './pages/referenties'
import Renovaties from './pages/renovaties'
import TeKoopPage from './pages/tekoop'

import underconstruction from './pages/underconstruction'

class Routes extends Component {
    render () {
        return (
            <Switch>
                
                <Route exact path='/main' component={MainPage} />
                <Route exact path='/nieuwbouw' component={Nieuwbouw} />
                <Route exact path='/renovaties' component={Renovaties} />
                <Route exact path='/contact' component={ContactPage} />
                <Route exact path='/referenties' component={Referenties} />
                <Route exact path='/tekoop' component={TeKoopPage} />
                <Route component={MainPage} />
            </Switch>
        )
    }
}

export default Routes
