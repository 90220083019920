import React from "react";
import { Fragment } from "react";
import Header from "../components/header/header";
import Banner from "../components/banner/banner";
import Footer from "../components/footer/footer";

import GalleryComponent from "../components/Gallery/gallery";
import { Link } from "react-router-dom";

export default function Nieuwbouw() {

    return (
        <Fragment>
            <Header />
            <Banner />

            <section className="page">
                <div className="container">
                    <header className="page-title">

                        <h1>Nieuwbouwwerken voor woningen en appartementen</h1>

                    </header>
                    <article className="content">

                        <p>Bouwwerken Thys is uw partner voor alle <strong>nieuwbouwwerken in Oudsbergen</strong> en omstreken.In een straal
                            van 60km rondom Oudsbergen behartigen wij in de provincies Limburg, Antwerpen en Vlaams-Brabant alle bouwwerken
                            voor nieuwbouwwoningen, villa’s en appartementen.</p>
                        <h2>Waaier aan methodes</h2>
                        <p>Wij voeren nieuwbouwwerken en <Link to="/renovaties">renovatiewerken</Link> op verschillende manieren uit.Daarbij
                            houden wij rekening met uw persoonlijke wensen en budget, maar ook met de mogelijkheden van het gebouw.</p>
                        <h3>Traditionele nieuwbouw</h3>
                        <p>Deze bouwvorm is veruit de meest gekende en bestaat uit het opmetselen van bouwstenen, waarbij de
                            tussenruimtes opgevoegd worden.Deze stijl wordt nog steeds vaak toegepast, met name voor woningen in
                            een landelijke stijl.</p>
                        <h3>Nieuwbouw met lijmtechniek</h3>
                        <p>Bij deze nieuwe methode van bouwen worden de gevelstenen gelijmd met behulp van een lijmspecie.
                            Deze wordt op het oppervlak gespoten.De voegen zijn daardoor dunner en het geheel oogt strakker en moderner.</p>
                        <h3>Nieuwbouw met dunbed mortel</h3>
                        <p>Deze <strong>bouwtechniek</strong> is een variatie op traditionele nieuwbouw en lijmen.De
                            stenen worden op een zeer dun bed van mortel gemetseld, dat nog steeds iets dikker zal zijn dan
                            bij de lijmtechniek.Deze techniek is goedkoper dan de lijmtechniek.</p>
                        <h3>Nieuwbouw met Zero®</h3>
                        <p>Deze bijzondere techniek werd ontwikkeld door de Vandersanden Group, dé referentie voor bouwstenen.De
                            stenen worden op de traditionele manier gemetseld maar worden gekanteld geplaatst zodat er geen voegen
                            zijn.De gevel met voegloos uitzicht oogt zeer modern.</p>
                        <h3>Binnenbouw en snelbouw</h3>
                        <p>Bij deze techniek worden de stenen eveneens gelijmd.Wij plaatsen deze muren met blokken van Wienerberger,
                            een merk dat bekend staat voor een uitstekende kwaliteit en duurzaamheid.</p>
                        <h3>Plaatsen van isolatie</h3>
                        <p>Wij hebben de speciale opleiding gevolgd om <strong>Eurowall plaatser</strong> te kunnen zijn.Wij
                            plaatsen dus ook spouwisolatie (binnen) van het merk Recticel.Deze isolatie kent een zeer hoge isolatiewaarde en
                            wordt vervaardigd op een manier dat ze zeer makkelijk en doeltreffend te plaatsen is, zonder al te grote werken
                            of omslachtige voorbereidingen.</p>
                        <h2>Informatie en contact</h2>
                        <p>Als gedreven bouwspecialist, besteden wij veel aandacht aan de huidige trends, normen en bouwvoorschriften.Wij
                            weten altijd uitstekend de combinactie te maken tussen noodzakelijke voorzieningen enerzijds en uw comfort,
                            luxe en persoonlijke verwachtingen anderzijds.</p>
                        <p>Wij hebben alle nodige certificaten, attesten en opleidingen om professionele bouwwerken te mogen uitvoeren.Bekijk
                            onze referenties&nbsp; en aarzel niet om <Link to="/contact">contact</Link> op te nemen indien u vragen heeft of
                            meer info wenst over onze diensten.</p>
                        <GalleryComponent />
                    </article>
                </div>

            </section>
            <section className="spacer"></section>

            <Footer />
        </Fragment >
    );
}