import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useLocation } from "react-router";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

import { Col, Container, Row } from 'reactstrap';
import { Link } from "react-router-dom";


export default function Banner() {
    const location = useLocation();
    //console.log('location : ', location.pathname);

    const isSelected = (f) => {
        //console.log('Check if selected : ', f, location.pathname);
        return location.pathname == f ? 'selected' : '';
    }

    return (
        <section className="banner">
            <div className="fluid_container">
                <Swiper 
                        navigation={true}
                        className="mySwiper" 
                        effect={'fade'} 
                        loop={true} 
                        pagination={{ "clickable": true }}
                        autoplay={{
                            "delay": 4500,
                            "disableOnInteraction": false
                          }} 
                          >
                    <SwiperSlide><img src="/assets/images/1.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/2.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/3.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/4.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/5.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/6.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/7.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/8.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/9.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/10.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/11.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/12.jpg" /></SwiperSlide>
                    <SwiperSlide><img src="/assets/images/13.jpg" /></SwiperSlide>
                    
                    
                </Swiper>
            </div>
            <Container>
                <div className="cta">
                    <p>
                        <a href="/">Uw specialist in nieuwbouw en renovatie<br />Gratis offertes</a>
                        <br />
                        <span className="tel-cta cta1">+32 (0)476/58 28 06</span>
                        <span className="tel-cta cta2">+32 (0)496/70 21 70</span>
                    </p>
                </div>
                <nav className="main-nav" id="navigation">
                    <div id="sticky-wrapper" className="sticky-wrapper" style={{ height: "68px" }}>
                        <div className="sticky-nav" style={{ width: "1140px" }}>
                            <ul className="sf-menu sf-js-enabled" >
                                <li className={isSelected('/')} id="menu_1">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className={isSelected('/nieuwbouw')} id="menu_2">
                                    <Link to="/nieuwbouw"  >Nieuwbouw</Link>
                                    
                                </li>
                                <li className={isSelected('/renovaties')} id="menu_3">
                                    <Link to="/renovaties">Renovaties</Link>
                                </li>
                                <li className={isSelected('/referenties')} id="menu_4">
                                    <Link to='referenties'>Referenties</Link>
                                </li>
                                <li className={isSelected('/tekoop')} id="menu_4b">
                                    <Link to="/tekoop">Te Koop</Link>
                                </li>
                                <li className={isSelected('/contact')} id="menu_5">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>

                        </div></div>
                </nav>
            </Container>
        </section>
    );
}